<template>
  <div class="product-slider__controls">
    <div v-if="fraction" class="product-slider__controls-fraction font font_m font_grey"></div>
    <div class="product-slider__arrow product-slider__arrow-prev">
      <div class="slider-arrow slider-arrow_prev_180"
           @mouseover="hoverLeft = true"
           @mouseleave="hoverLeft = false"
      >
        <svg-icon v-show="!hoverLeft" :name="icons.carrot" width="24" height="24" />
        <svg-icon v-show="hoverLeft" :name="icons.carrotHover" width="24" height="24" />
      </div>
    </div>
    <div class="product-slider__arrow product-slider__arrow-next">
      <div class="slider-arrow"
           @mouseover="hoverRight = true"
           @mouseleave="hoverRight = false"
      >
        <svg-icon v-show="!hoverRight" :name="icons.carrot" width="24" height="24" />
        <svg-icon v-show="hoverRight" :name="icons.carrotHover" width="24" height="24" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderArrows',
  props: {
    fraction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        carrot: 'arrow-round-gray',
        carrotHover: 'arrow-round',
      },
      hoverLeft: false,
      hoverRight: false,
    }
  },
}
</script>

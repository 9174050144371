<template>
  <div class="product-slider__wrapper">
    <div class="product-slider" :class="sliderClass">
      <div class="product-slider__title-wrapper">
        <div
          class="product-slider__title font font_title-xl font_bold font_center"
          :class="{ 'font_center' : titleCenter }"
        >
          {{ title }}
        </div>
        <SliderArrows v-if="isNavigationHeader" :fraction="isSliderPagination" />
      </div>
      <div class="product-slider__wrapper-body">
        <SliderArrows v-if="isNavigationBlock" :fraction="false" class="product-slider__controls_block" />
        <div
          v-swiper:productSlider="sliderOptions"
          class="product-slider__container _swiper"
        >
          <div class="swiper-wrapper">
            <div
              v-for="product in products"
              :key="product.id"
              ref="swiperSlides"
              class="swiper-slide"
              :data-key="product.productId"
              @click="selectItemEvent(product)"
            >
              <ProductCard :item="product" :loading="loop ? 'default' : 'lazy'" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as amplitudeTracker from '@amplitude/analytics-browser';
import { directive } from 'vue-awesome-swiper';
import ProductCard from '~/components/catalog/productCard';
import SliderArrows from '~/components/elements/SliderArrows';
import {findMaxNumber} from '~/plugins/helpers/catalog';

export default {
  components: {
    SliderArrows,
    ProductCard,
  },
  directives: {
    swiper: directive,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    titleCenter: {
      type: Boolean,
      default: true,
    },
    products: {
      type: Array,
      required: true,
    },
    sliderClass: {
      type: String,
      required: true,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    breakpoints: {
      type: Object,
      default() {
        return {}
      },
    },
    pagination: {
      type: String,
      default: 'none',
    },
    navigation: {
      type: String,
      default: 'block',
    },
  },
  data() {
    const defaultBreakpoints = {
      // when window width is >= 320px
      320: {
        spaceBetween: 0,
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      // when window width is >= 768px
      574.98: {
        spaceBetween: 12,
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      767.98: {
        spaceBetween: 12,
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      // when window width is >= 992px
      991.98: {
        spaceBetween: 24,
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      1200: {
        spaceBetween: 24,
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
    }

    let breakpoints = {}

    if (Object.keys(this.breakpoints).length) {
      breakpoints = this.breakpoints
    } else {
      breakpoints = defaultBreakpoints
    }

    let pagination = {}
    let navigation = {}

    if (this.pagination !== 'none') {
      const el = `.${this.sliderClass} .${this.pagination === 'fraction'
        ? 'product-slider__controls-fraction'
        : 'product-slider__dotts'}`

      pagination = { el, clickable: true, type: this.pagination }
    }

    if (this.navigation !== 'none') {
      navigation = {
        nextEl: `.${this.sliderClass} .product-slider__arrow-next`,
        prevEl: `.${this.sliderClass} .product-slider__arrow-prev`,
      }
    }

    return {
      icons: {
        carrot: 'arrow-small',
      },
      sliderOptions: {
        observer: true,
        observeParents: true,
        spaceBetween: 24,
        centeredSlides: false,
        freeMode: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        loop: this.loop,
        preloadImages: false,
        pagination,
        navigation,
        breakpoints,
      },
      visibleItems: [],
      amplitudeItems: [],
    }
  },
  computed: {
    minSlides() {
      const keys = Object.keys(this.sliderOptions.breakpoints).map(key => +key)

      const maxKey = findMaxNumber(keys) || 0

      if (!maxKey) return 0

      return this.sliderOptions.breakpoints[maxKey].slidesPerView
    },
    isSliderPagination() {
      return this.pagination !== 'none'
    },
    isNavigationHeader() {
      return this.navigation === 'header' && this.products?.length > this.minSlides
    },
    isNavigationBlock() {
      return this.navigation === 'block' && this.products?.length > this.minSlides
    },
  },
  mounted() {
    this.observeVisibility();
  },
  methods: {
    observeVisibility() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const productId = entry.target.getAttribute('data-key');
            const product = this.products.find(
              product => product.productId.toString() === productId,
            );

            if (product) {
              this.visibleItems.push({
                item_name: product.name,
                item_id: `${product.productId}`,
                price: product.price.value,
                discount: product.oldPrice
                ? product.oldPrice.value - product.price.value
                : '',
                item_brand: product.brandName,
                item_category: product.categoryName,
                item_list_name: `main ${this.title}`,
              });
              this.amplitudeItems.push({
                item_id: `${product.productId}`,
                item_brand: product.brandName,
                item_name: product.name,
                price: product.price.value,
              });
              observer.unobserve(entry.target);
              this.sendGA4Event();
            }
          }
        });
      }, options);

      this.$refs.swiperSlides.forEach(slide => {
        observer.observe(slide);
      });
    },
    sendGA4Event() {
    if (this.visibleItems.length >= 4) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'view_item_list',
          ecommerce: {
            items: this.visibleItems,
          },
        });
        amplitudeTracker.track('view_selection', {
          selection_name: this.title,
          products: this.amplitudeItems,
        });
      }
    },
    selectItemEvent(product) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
          items: [{
            item_name: product.name,
            item_id: `${product.productId}`,
            price: product.price.value,
            discount: product.oldPrice
            ? product.oldPrice.value - product.price.value
            : '',
            item_brand: product.brandName,
            item_category: product.categoryName,
            item_list_name: `main ${this.title}`,
          }],
        },
      });
      amplitudeTracker.track('click_selection_item', {
        selection_name: this.title,
        products: [{
          item_id: `${product.productId}`,
          item_brand: product.brandName,
          item_name: product.name,
          price: product.price.value,
        }],
      });
    },
  },
}
</script>
